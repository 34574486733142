import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { getUserAuthData, getUserData } from "../hooks/HelperFunctions";
import { deepPurple } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Logo from "../../assets/imgs/admin-logo.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// Menu Icons
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import websiteLogo from "../../assets/imgs/AfnanInnovationLab-Logo-Color@2x.png";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/features/authSlice";

import "../../assets/css/style.css";
import "./Header.css";
import "../../assets/css/form.css";
import { getProfileAvatarFromName } from "../../utils/helpers";
import { useGetSchoolQuery } from "../../redux/features/schools/schoolApi";

function Header(props) {
  const takbirLogo =
    "https://cdn.mytakbir.com/static-assets/logos/takbir-lms-learning-management-system-muslim-logo-3x.png";
  const takbirUrl = "https://portal.deenschool.io/";

  const qafysLogo =
    "https://web-assets.deenschool.io/assets/logos/deen-school-logos-2x/deenschool-islamic-school-management-logo-cl.png";
  const dsLogoUrl = "https://portal.deenschool.io/";

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  console.log(user.config.data, "loged user");

  const navigate = useNavigate();

  const { schoolId } = useSelector((state) => state.auth.user);
  const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId);

  const [schoolInfo, setSchoolInfo] = useState({});

  useEffect(() => {
    if (!isLoading && data && !error) {
      setSchoolInfo(data?.body?.data);
    }
  }, [data, error, isLoading]);

  const handleLogout = () => {
    dispatch(logout());
    // * custom navigate
    navigate("/signin");
  };

  // Logout menue unauthorized
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Logout menue unauthorized

  // ====== Header Scroll Start ======//
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);

  // handle scroll event
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  // add/remove scroll event listener
  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);
  // ====== Header Scroll End ======//

  console.log(user, 2002);

  return (
    <div className="" style={{ marginTop: sticky.offset }}>
      <div
        id="sticky-header"
        className={`navbar${sticky.isSticky ? " sticky" : ""}`}
        ref={headerRef}
      >
        {props.AlertBody}

        <div className="HeaderContainer">
          <div className="Header">
            <div className="HdrLeftCol">
              <div className="IconMenu">{props.IconMenu}</div>
              {takbirLogo?.length > 0 && (
                <Link
                  className="Branding"
                  to={takbirUrl?.length > 0 ? takbirUrl : "#"}
                >
                  {/* <img src={schoolLogo != "" ? schoolLogo : qafysLogo} /> */}
                  <img src={websiteLogo} />
                </Link>
              )}
            </div>

            <div className="HdrRightCol">
              <div className="DesktopView">{props.ButtonBody}</div>

              {/* <Button variant="text" className="NotificationButton">
              <div className="NotificationCount">1</div>
              <NotificationsRoundedIcon />
              </Button> */}

              {/* <Avatar onClick={handleClick}>{Array.from(userData.firstName)[0]}</Avatar> */}

              <div
                className="ProfileSection BorderLeft"
                role="button"
                onClick={handleClick}
              >
                {/* <Avatar
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{ bgcolor: deepPurple[300] }}
                  src={user?.profilePic}
                >
                  {getProfileAvatarFromName(user)}
                </Avatar> */}
                <Avatar></Avatar>
                <h1>{user?.name}</h1>
                {/* <h1>{`${user?.firstName || "---"} ${
                  user?.lastName || "---"
                }`}</h1> */}
                <KeyboardArrowDownRoundedIcon sx={{ color: "#77767A" }} />
              </div>
              {/* :
              <Avatar
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}

                sx={{ bgcolor: deepPurple[300] }}
              >S</Avatar> */}

              <div className="">
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="DropdownMenu"
                >
                  {/* <MenuItem onClick={handleClose}>
                    <div className="ProfileSection">
                      {userData.image ?
                        <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} src={userData.image ? userData.image : ""} ></Avatar>
                        :
                        <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} > {userData.firstName ? Array.from(userData.firstName)[0] : ""} </Avatar>
                      }
                      <div><h1>{userData.firstName} {userData.lastName}</h1><p><a href="#">{userData.email}</a></p></div>
                    </div>
                  </MenuItem> */}

                  <MenuItem onClick={handleClose}>
                    <div className="ProfileSection">
                      <Avatar></Avatar>
                      {/* <Avatar
                        sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                        src={user?.profilePic ? user.profilePic : ""}
                      >
                        {" "}
                        {getProfileAvatarFromName(user)}{" "}
                      </Avatar> */}
                      <div>
                        <h1>{`${user?.firstName || "---"} ${
                          user?.lastName || "---"
                        }`}</h1>
                        <p>
                          <a href="#">{user?.email || "----"}</a>
                        </p>
                      </div>
                    </div>
                  </MenuItem>

                  {/* <span className="divider"></span> */}
                  {/* <MenuItem onClick={handleClose}><HomeRoundedIcon /> Home</MenuItem> */}
                  {/* <MenuItem onClick={handleClose}><MenuBookRoundedIcon /> Courses</MenuItem> */}
                  {/* <span className="divider"></span> */}
                  {/* <MenuItem onClick={handleClose}><NotificationsRoundedIcon /> Alerts</MenuItem>
                <MenuItem onClick={handleClose}><QuestionAnswerRoundedIcon /> Messages</MenuItem> */}
                  {/* <MenuItem onClick={handleClose}><InsertInvitationRoundedIcon /> Calendar</MenuItem> */}
                  {/* <span className="divider"></span> */}
                  {/* <MenuItem onClick={handleClose}><EmojiEventsRoundedIcon /> Certificates</MenuItem> */}
                  {/* <MenuItem onClick={handleClose}><SettingsRoundedIcon /> Settings</MenuItem> */}

                  <span className="divider"></span>
                  <MenuItem onClick={() => handleLogout()}>
                    <LogoutIcon /> Log Out
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          {props.SidebarBody}
        </div>
      </div>
    </div>
  );
}

export default Header;
