import { api } from "../../api/apiSlice";
const qrManagementApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findAllUsers: builder.query({
      query: () => "usersV1/private/findAllUsers",
      providesTags: ["QrUsers"],
    }),
    findAllQr: builder.query({
      query: () => "qr-codesV1/findAllQrCodes",
      providesTags: ["QrUsers"],
    }),
    getSingleQrCode: builder.query({
      query: (id) => `qr-codesV1/getSingle/${id}`,
      providesTags: ["QrUsers"],
    }),
    createQrCode: builder.mutation({
      query: (data) => ({
        url: "qr-codesV1/createQrCode",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["QrUsers"],
    }),
    updateQrCode: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `qr-codesV1/updateOne/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["QrUsers"],
    }),
    deleteQrCode: builder.mutation({
      query: (id) => ({
        url: `qr-codesV1/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["QrUsers"],
    }),
  }),
});

export const {
  useFindAllUsersQuery,
  useFindAllQrQuery,
  useGetSingleQrCodeQuery,
  useCreateQrCodeMutation,
  useUpdateQrCodeMutation,
  useDeleteQrCodeMutation,
} = qrManagementApi;
