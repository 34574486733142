import React, { useEffect, useState } from "react";
import { useGetSingleQrCodeQuery } from "../../redux/features/qrManagementapi/qrManagementApi";
import QRimg from "../../assets/imgs/qr-afnanilab.careers.png";
import { Skeleton } from "@mui/material";

const QrDetails = ({ itemId }) => {
  const [itemDetails, setItemDetails] = useState(null);
  const { data, isError, isLoading } = useGetSingleQrCodeQuery(itemId);
  useEffect(() => {
    if (!isLoading && !isError) {
      console.log(data.body.data, 2012);
      setItemDetails(data.body.data);
    }
  }, [data, isError, isLoading]);

  return (
    <div>
      <div className="FormFieldContainer QrDetailsContainer">
        <div className="FloatLabelCol">QR code name</div>
        <div className="FormFieldCol">
          {itemDetails === null ? (
            <Skeleton variant="text" sx={{ width: "50%", fontSize: "1rem" }} />
          ) : (
            itemDetails?.name
          )}
        </div>
      </div>

      <div className="FormFieldContainer QrDetailsContainer">
        <div className="FloatLabelCol">URL</div>
        <div className="FormFieldCol">
          {/* https://stage-portal.deenschool.io/authentication/signin */}
          {itemDetails === null ? (
            <Skeleton variant="text" sx={{ width: "70%", fontSize: "1rem" }} />
          ) : (
            itemDetails?.url
          )}
        </div>
      </div>

      <div
        className="FormFieldContainer QrDetailsContainer"
        style={{
          alignItems: "flex-start",
        }}
      >
        <div className="FloatLabelCol">QR code</div>
        <div className="FormFieldCol">
          {/* <img src={QRimg} alt="qr images" /> */}
          {itemDetails === null ? (
            <Skeleton
              variant="rounded"
              sx={{ width: "60%", height: "200px" }}
            />
          ) : (
            <img src={itemDetails?.qr_code_url} alt={itemDetails?.name} />
          )}
        </div>
      </div>
    </div>
  );
};

export default QrDetails;
