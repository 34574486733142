import { Navigate, useLocation } from "react-router-dom";
import { getUserAuthData, getUserData } from "../hooks/HelperFunctions";
import Header from "../components/Header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import { useSelector } from "react-redux";
import { useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SideBarDrawer from "../components/SideBarDrawer/SideBarDrawer";
// import TabName from "../components/TabName/TabName";

const AdminLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  console.log(user, "user");
  if (!user) {
    return (
      <Navigate to="/signin" state={{ from: location.pathname }} replace />
    );
  }

  return (
    <>
      <div>
        <div className="DesktopView">
          <Header />
          <Sidebar />
        </div>

        <div className="ResponsiveMenu">
          <Header
            IconMenu={
              <a onClick={handleDrawerOpen}>
                <MenuRoundedIcon />
              </a>
            }
          />
          <SideBarDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            DrawerBody={<Sidebar />}
          />
        </div>
      </div>

      <div className="app-body"> {children} </div>
    </>
  );
};

export default AdminLayout;
