import { Routes, Route } from "react-router-dom";
import "./App.css";
// import Layout from "./routing/layout";
import SignIn from "./pages/authentication/SignIn";
import Home from "./pages/Home/Home";
import Otp from "./pages/authentication/Otp";
import AdminLayout from "./routing/adminLayout";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    if (tokenExpiry && Date.now() > parseInt(tokenExpiry)) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiry");
      navigate("/signin");
    }
  });

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <AdminLayout>
              <Home />
            </AdminLayout>
          }
        />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/otp" element={<Otp />} />
      </Routes>
    </>
  );
}

export default App;
