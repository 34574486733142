import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import {
  useFindAllQrQuery,
  useCreateQrCodeMutation,
  useGetSingleQrCodeQuery,
  useDeleteQrCodeMutation,
  useUpdateQrCodeMutation,
} from "../../redux/features/qrManagementapi/qrManagementApi";

import Table from "../../components/Table/Table";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import DraggableDialog from "../../components/hooks/Dialog";
import UrlLink from "./UrlLink";
import UTM from "./UTM";
import QrDetails from "./QrDetails";
import { toast } from "react-toastify";

const Home = () => {
  // * === url === * //
  const [urlQrName, setUrlQrName] = useState("");
  const [clientProjName, setClientProjName] = useState("");
  const [url, setUrl] = useState("");
  const [urlErrors, setUrlErrors] = useState({
    urlQrName: "",
    clientProjName: "",
    url: "",
  });

  // * === utm === * //
  const [urlUtmName, setUrlUtmName] = useState("");
  const [utmClientProjName, setUtmClientProjName] = useState("");
  const [utmBaseUrl, setUtmBaseUrl] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmErrors, setUtmErrors] = useState({
    urlUtmName: "",
    utmClientProjName: "",
    utmBaseUrl: "",
    utmCampaign: "",
    utmMedium: "",
    utmSource: "",
  });

  // * ===== * //
  const [singleItemImageUrl, setSingleItemImageUrl] = useState("");
  const [itemId, setItemId] = useState(""); // ** every item id
  const [itemUrlType, setItemUrlType] = useState("");
  const [rows, setRows] = useState([]);
  const [deleteQrItem, setDeleteQrItem] = useState("");
  const [tabUrlType, setTabUrlType] = useState(0);
  const [urlData, setUrlData] = useState({
    name: urlQrName,
    project: clientProjName,
    url: url,
    type: "url",
  });

  const [utmData, setUtmData] = useState({
    name: urlUtmName,
    project: utmClientProjName,
    url: utmBaseUrl,
    type: "utm",
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  });

  const [drawer, setDrawer] = useState(false);
  const [detailsDrawer, setDetailsDrawer] = useState(false);
  const [deleteDialoge, setDeleteDialoge] = useState(false);

  // * ========= api start ========= * //
  const { data, isError, isLoading } = useFindAllQrQuery();
  const {
    data: singleData,
    isError: isSingleItemError,
    isLoading: isSingleLoading,
  } = useGetSingleQrCodeQuery(itemId);
  const dispatch = useDispatch();
  const [createQrCodeMutation] = useCreateQrCodeMutation();
  const [deleteItem] = useDeleteQrCodeMutation();
  const [updateTemplate] = useUpdateQrCodeMutation();
  // * ========= data ======= * //
  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, isError, isLoading]);

  useEffect(() => {
    if (!isSingleLoading && !isSingleItemError) {
      setSingleItemImageUrl(singleData.body.data);
    }
  }, [singleData, isSingleItemError, isSingleLoading]);
  // * ========= api  end ========= * //

  // * ========= submit handlers start ========= * //

  // const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  // * === url === * //
  const urlFormClear = () => {
    setUrlQrName("");
    setClientProjName("");
    setUrl("");
  };

  const handleUrlSubmit = async () => {
    const urlData = {
      name: urlQrName,
      project: clientProjName,
      url: url,
    };

    let formIsValid = true;
    const newErrors = {
      urlQrName: "",
      clientProjName: "",
      url: "",
    };

    if (!urlQrName) {
      formIsValid = false;
      newErrors.urlQrName = "Please enter QR code name.";
    }

    if (!clientProjName) {
      formIsValid = false;
      newErrors.clientProjName = "Please enter client/project name.";
    }

    if (!url) {
      formIsValid = false;
      newErrors.url = "Please enter URL.";
    } else if (!urlRegex.test(url)) {
      formIsValid = false;
      newErrors.url = "Please enter a valid URL.";
    }
    // setUrlData(urlData);
    console.log(urlData, 9887);
    setUrlErrors(newErrors);
    return formIsValid;
  };

  // * === utm === * //

  const utmFormClear = () => {
    setUrlUtmName("");
    setUtmClientProjName("");
    setUtmBaseUrl("");
    setUtmCampaign("");
    setUtmMedium("");
    setUtmSource("");
  };

  const handleUtmSubmit = async () => {
    const utmData = {
      name: urlUtmName,
      project: utmClientProjName,
      url: utmBaseUrl,
      type: "utm",
      utm_campaign: utmCampaign,
      utm_medium: utmMedium,
      utm_source: utmSource,
    };

    let formIsValid = true;
    const newErrors = {
      urlUtmName: "",
      utmClientProjName: "",
      utmBaseUrl: "",
      utmCampaign: "",
      utmMedium: "",
      utmSource: "",
    };

    if (!urlUtmName) {
      formIsValid = false;
      newErrors.urlUtmName = "Please enter QR code name.";
    }

    if (!utmClientProjName) {
      formIsValid = false;
      newErrors.utmClientProjName = "Please enter client/project name.";
    }

    if (!utmBaseUrl) {
      formIsValid = false;
      newErrors.utmBaseUrl = "Please enter URL.";
    } else if (!urlRegex.test(utmBaseUrl)) {
      formIsValid = false;
      newErrors.utmBaseUrl = "Please enter a valid URL.";
    }

    if (!utmCampaign) {
      formIsValid = false;
      newErrors.utmCampaign = "Please enter utm_campaign.";
    }

    if (!utmMedium) {
      formIsValid = false;
      newErrors.utmMedium = "Please enter utm_medium.";
    }

    if (!utmSource) {
      formIsValid = false;
      newErrors.utmSource = "Please enter utm_source.";
    }
    setUtmErrors(newErrors);
    console.log(utmData, 9887);
    setUtmData(utmData);
    return formIsValid;
  };

  // * ========= submit handlers end ========= * //

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "50%",
      textAlign: "left",
      sort: "sort",
      //   headerOnClick: () => {},
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Client/Project",
      width: "15%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Type",
      width: "15%",
      textAlign: "left",
      sort: "sort",
      //   headerOnClick: () => {},
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Generated",
      width: "15%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "5%",
      textAlign: "left",
    },
  ];

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        const localDate = new Date(item?.created_at);
        const momentFormattedDate = moment(item?.created_at).format(
          "DD-MMM-YYYY, h:mm A"
        );
        const formattedDate = `${localDate.toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}, ${localDate.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZone: "America/New_York",
        })} (EST)`;
        return {
          cell: [
            { textBold: item?.name },
            { textBold: item?.project },
            { textBold: item?.type },
            // { textBold: "17-Feb-2024, 7:00 PM (EST)" },
            // { textBold: item?.created_at },
            // { textBold: formattedDate },
            { textBold: momentFormattedDate },

            {
              ActionButtonDotIcon: true,
              ActionButtonLabel: "Action",
              TextAlign: "right",
            },
          ],
          //======= Action Date Start===== //
          action: [
            { label: "Details", onClick: () => handleItemDetails(item.id) },
            {
              label: "Download",
              onClick: () => handleDownload(item.qr_code_url),
            },
            {
              label: "Regenerate",
              onClick: () => handleItemRegenarate(item.id, item.type),
            },
            { label: "Delete", onClick: () => handleDeleteDialog(item.id) },
          ],
          //======= Action Date End===== //
        };
      });
    }
  };

  // * ================== row data delete start  ================== * //

  const handleDeleteDialog = (id) => {
    setDeleteDialoge(true);
    setDeleteQrItem(id);
  };

  const handleDeleteItem = async () => {
    try {
      await deleteItem(deleteQrItem);
      toast.success("Item deleted successfully.");
      setDeleteDialoge(false);
    } catch (error) {
      console.error("Delete error:", error);
      toast.error("Error deleting item.");
    }
  };
  // * ================== row data delete end  ================== * //

  // * ================== others func start ================== * //

  const handleDownload = (qrCodeUrl) => {
    // Create a temporary anchor element
    const anchor = document.createElement("a");
    anchor.href = qrCodeUrl;
    anchor.download = "qr_code.png";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleItemDetails = (id) => {
    setDetailsDrawer(true);
    setItemId(id);
  };

  const handleItemRegenarate = (id, type) => {
    setDrawer(true);
    setItemId(id);
    setItemUrlType(type);
  };

  useEffect(() => {
    setUrlData(urlData);
  }, [urlData]);

  useEffect(() => {
    setUrlData({
      name: urlQrName,
      project: clientProjName,
      url: url,
      type: "url",
    });
  }, [urlQrName, clientProjName, url]);

  useEffect(() => {
    setUtmData({
      name: urlUtmName,
      project: utmClientProjName,
      url: utmBaseUrl,
      type: "utm",
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    });
  }, [
    urlUtmName,
    utmClientProjName,
    utmBaseUrl,
    utmSource,
    utmMedium,
    utmCampaign,
  ]);

  const handleSubmit = async () => {
    let checkValid = false;

    if (tabUrlType === 0) {
      const firstTabValidation = await handleUrlSubmit();
      if (firstTabValidation === true) {
        checkValid = true;
      }
    } else {
      const secondValidation = await handleUtmSubmit();
      if (secondValidation === true) {
        checkValid = true;
      }
    }

    if (checkValid) {
      console.log(checkValid);
      if (itemId) {
        console.log("testing app", 22);
      } else {
        if (tabUrlType === 0) {
          await createQrCodeMutation(urlData);
          // const response = await createQrCodeMutation(urlData);
          // console.log(response, 2003);
          urlFormClear();
          setDrawer(false);
        } else {
          await createQrCodeMutation(utmData);
          // const response = await createQrCodeMutation(utmData);
          // console.log(response, 2003);
          utmFormClear();
          setDrawer(false);
        }
      }
    }

    // * =========== * //

    // if (checkValid) {
    //   console.log(checkValid);
    //   if (tabUrlType === 0) {
    //     if (itemId) {
    //       // Prepare updated data
    //       const updatedData = {
    //         id: itemId,
    //         name: urlQrName,
    //         project: clientProjName,
    //         url: url,
    //         type: "url",
    //       };
    //       // Call updateTemplate for updating existing data
    //       await updateTemplate(urlData, itemId);
    //       setDrawer(false);
    //     } else {
    //       await createQrCodeMutation(urlData);
    //     }
    //     urlFormClear();
    //   } else {
    //     if (itemId) {
    //       // Prepare updated data
    //       const updatedData = {
    //         id: itemId,
    //         name: urlUtmName,
    //         project: utmClientProjName,
    //         url: utmBaseUrl,
    //         type: "utm",
    //         utm_source: utmSource,
    //         utm_medium: utmMedium,
    //         utm_campaign: utmCampaign,
    //       };
    //       // Call updateTemplate for updating existing data
    //       await updateTemplate(utmData, itemId);
    //       // Close the drawer after update
    //       setDrawer(false);
    //     } else {
    //       await createQrCodeMutation(utmData);
    //     }
    //     utmFormClear();
    //   }
    // }
  };

  const handleTabChange = (event) => {
    console.log(event, 234);
    setTabUrlType(event);
  };

  const handleNewQrCodeDrawerOpen = () => {
    setDrawer(true);
    setItemId("");
    setTabUrlType("");
  };

  // useEffect(() => {
  //   setItemId(itemId);
  // }, [setItemId, itemId]);

  // * =================== others func end =================== * //

  const tabData = [
    {
      lebel: "URL/Link",
      icon: "",
      // tabDisabled: itemId && tabUrlType === 0 ? false : true,
    },
    {
      lebel: "UTM",
      icon: "",
      // tabDisabled: itemId && tabUrlType === 1 ? false : true,
    },
  ];

  const panelTab = [
    {
      id: 0,
      panleBody: (
        <UrlLink
          url={url}
          setUrl={setUrl}
          urlQrName={urlQrName}
          setUrlQrName={setUrlQrName}
          clientProjName={clientProjName}
          setClientProjName={setClientProjName}
          errors={urlErrors}
          setErrors={setUrlErrors}
          itemId={itemId}
        />
      ),

      SaveButtonLabel: "Generate qr code",
      clickSaveButton: handleSubmit,
      CancelButtonLabel: "Cancel",

      Checkbox: false,
      CheckboxLabel: "Add another after saving",
      // onClickCheckbox:onClickCheckbox,

      // DeleteButton: false,
      // DeleteLebel: "Delete Permanently",
      // onClickCheckbox: handleCheckboxClicked,
      handleTabChange: handleTabChange,
      // onClickDelete:onClickDelete,
    },

    {
      id: 1,
      panleBody: (
        <UTM
          urlUtmName={urlUtmName}
          setUrlUtmName={setUrlUtmName}
          utmClientProjName={utmClientProjName}
          setUtmClientProjName={setUtmClientProjName}
          utmBaseUrl={utmBaseUrl}
          setUtmBaseUrl={setUtmBaseUrl}
          utmCampaign={utmCampaign}
          setUtmCampaign={setUtmCampaign}
          utmMedium={utmMedium}
          setUtmMedium={setUtmMedium}
          utmSource={utmSource}
          setUtmSource={setUtmSource}
          utmErrors={utmErrors}
          setUtmErrors={setUtmErrors}
          itemId={itemId}
        />
      ),
      SaveButtonLabel: "Generate qr code",
      clickSaveButton: handleSubmit,

      cancelButtonLabel: "Cancel",
      //   videoUrl: videoUrl,
      //   videoName: videoName,
      CancelButtonLabel: "Cancel",
      handleTabChange: handleTabChange,
      Checkbox: false,
      CheckboxLabel: "Add another after saving",
      //   onClickCheckbox: handleCheckboxClicked,
      arrowIcon: false,
    },
  ];
  return (
    <div>
      <div className="TableSection">
        <Table
          title="QR Codes"
          tableHeader={true}
          addLabel="New qr code"
          // icon={<AddIcon />}
          AddButton={true}
          headCells={headCells}
          rows={rows}
          onClick={handleNewQrCodeDrawerOpen}
          // onClick={() => setDrawer(true)}
          footer={true}
          //   handleSearchText={handleSearchText}
          //   handleSearch={handleSearch}
          //   setResetSearchTerm={setResetSearchTerm}
          //   setSearchTerm={setSearchTerm}
          //   resetsearchTerm={resetsearchTerm}
          //   pageNumberFromOutSide={pageNumberFromOutSide}
          innerHeader={true}
          showSearchBox={false}
          searchBoxPlaceholder="Search courses"
          //   isDataLoading={isLoading || options?.isLoading}
          RowsPerPage={20}
          SearchBoxTop={true}
          emptyTableTitle="No course added yet"
          emptyTableNote="No items available"
          showButton={true}
          btnLabel="New qr code"
          AddButtonInner={false}
          addLabelInner="New qr code"
          InnerButtonColor="#0450E1"
          InnerButtonTextColor="#fff"
          //   onClickEmptyCard={handleFilterDrawerOpen}
        />
      </div>

      {/* ============= QR generate Drawer ============== */}
      <SideDrawer
        open={drawer}
        handleDrawerClose={() => setDrawer(false)}
        title={itemId ? "Edit QR Code" : "New QR Code"}
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel={"Generate qr code"}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={false}
        handleTabChange={handleTabChange}
        // clickSaveButton={addCourseHandler}
        // body={<>hello</>}
        //=== Tabs Codes ===//
        controlledValue={itemUrlType && itemUrlType === "url" ? 0 : 1}
        TabsStatus={true}
        TabsPannelStatus={true}
        panelTab={panelTab}
        tabData={tabData}
      />
      {/* ============= Details Drawer ============== */}
      <SideDrawer
        open={detailsDrawer}
        handleDrawerClose={() => setDetailsDrawer(false)}
        title={"QR Code Details"}
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel={"Download"}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={() => handleDownload(singleItemImageUrl?.qr_code_url)}
        body={<QrDetails itemId={itemId} />}
        //=== Tabs Codes ===//
        // TabsStatus={true}
        // TabsPannelStatus={true}
        // panelTab={panelTab}
        // tabData={tabData}
      />
      {/* ============= Delete Drawer ============== */}
      <DraggableDialog
        openDialog={deleteDialoge}
        handleCloseDialog={() => setDeleteDialoge(false)}
        title="Delete course?"
        body={
          <div>
            Are you sure you want to delete this course?
            <br /> Once you delete it, you can't get it back.
          </div>
        }
        ModalFooter={true}
        handleConfirmCloseDialog={handleDeleteItem}
        actionButton="Delete"
      />
    </div>
  );
};

export default Home;

//  * temp table row data * //
// const temprows = [
//   {
//     cell: [
//       { textBold: "Takbir Course Promotion" },
//       { textBold: "Takbir LMS" },
//       { textBold: "URL/Link" },
//       { textBold: "17-Feb-2024, 7:00 PM (EST)" },
//       {
//         ActionButtonDotIcon: true,
//         ActionButtonLabel: "Action",
//         TextAlign: "right",
//       },
//     ],
//     //======= Action Date Start===== //
//     action: [
//       { label: "Details", onClick: () => setDetailsDrawer(true) },
//       { label: "Download", onClick: () => {} },
//       { label: "Regenerate", onClick: () => setDrawer(true) },
//       { label: "Delete", onClick: () => setDeleteDialoge(true) },
//     ],
//     //======= Action Date End===== //
//   },
// ];
