import React, { useEffect, useState } from "react";
import TextField from "../../components/Form/TextField/TextField";
import {
  useGetSingleQrCodeQuery,
  useUpdateQrCodeMutation,
} from "../../redux/features/qrManagementapi/qrManagementApi";

const UrlLink = (props) => {
  const {
    url,
    setUrl,
    urlQrName,
    setUrlQrName,
    clientProjName,
    setClientProjName,
    errors,
    itemId,
  } = props;

  const [itemDetails, setItemDetails] = useState(null);
  const { data, isError, isLoading } = useGetSingleQrCodeQuery(itemId);
  const [updateQrCode, { error: updateError }] = useUpdateQrCodeMutation();
  useEffect(() => {
    if (!isLoading && !isError) {
      console.log(data.body.data, 2012);
      setItemDetails(data.body.data);
    }
  }, [data, isError, isLoading]);

  useEffect(() => {
    if (itemDetails && itemDetails.name) {
      setUrlQrName(itemDetails.name);
    }
    if (itemDetails && itemDetails.project) {
      setClientProjName(itemDetails.project);
    }

    if (itemDetails && itemDetails.url) {
      setUrl(itemDetails.url);
    }
  }, [
    itemDetails,
    url,
    setUrl,
    urlQrName,
    setUrlQrName,
    clientProjName,
    setClientProjName,
  ]);

  const handleInputChange = (field, event) => {
    const newValue = event;
    switch (field) {
      case "urlQrName":
        setUrlQrName(newValue);
        break;
      case "clientProjName":
        setClientProjName(newValue);
        break;
      case "url":
        setUrl(newValue);
        break;
      default:
        break;
    }
    console.log(newValue);
  };

  return (
    <div>
      {/* ========== QR name ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">QR code name</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Enter QR code name"
            count={40}
            name={"name"}
            required={true}
            helperText={errors.urlQrName}
            error={errors.urlQrName !== ""}
            value={urlQrName}
            handleChange={(e) => handleInputChange("urlQrName", e)}
          />
        </div>
      </div>
      {/* ========== Client Project ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">Client/Project</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Enter client/project name"
            count={50}
            name={"name"}
            required={true}
            helperText={errors.clientProjName}
            error={errors.clientProjName !== ""}
            value={clientProjName}
            handleChange={(e) => handleInputChange("clientProjName", e)}
          />
        </div>
      </div>
      {/* ========== URL ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">URL</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="http://"
            inputType="url"
            name={"name"}
            required={true}
            helperText={errors.url}
            error={errors.url !== ""}
            value={url}
            handleChange={(e) => handleInputChange("url", e)}
          />
        </div>
      </div>
    </div>
  );
};

export default UrlLink;
