import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import PortalSwitcher from "../../components/PortalSwitcher/PortalSwitcher";
import { useLocation } from "react-router-dom";

import ClassRoundedIcon from "@mui/icons-material/ClassRounded";
import LogoutIcon from "@mui/icons-material/Logout";

import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/features/authSlice";

import { AiOutlineCalendar } from "react-icons/ai";
import { AiFillCalendar } from "react-icons/ai";
import { AiOutlineSetting } from "react-icons/ai";
import { RiHome4Fill } from "react-icons/ri";
import { RiPresentationLine } from "react-icons/ri";
import { RxPerson } from "react-icons/rx";
import { RxHome } from "react-icons/rx";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import websiteLogo from "../../assets/imgs/AfnanInnovationLab-Logo-Color@2x.png";
import CustomMenuItem from "./CustomMenuItem";
import "../../assets/css/SideBarMenu.css";
import { useGetSchoolQuery } from "../../redux/features/schools/schoolApi";
import useProtected from "../../hooks/useProtected";
import { useEffect } from "react";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import {
  isTheActionPermitted,
  isTheSectionPermitted,
} from "../../hooks/HelperFunctionsPermission";

function SideBarMenu(props) {
  // getting data from env file
  const dataFromEnvFile = process.env.REACT_APP_ENV;

  // const schoolInformation = useSelector(state => state.auth.user);
  const { schoolId } = useSelector((state) => state.auth.user);

  const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId);

  useProtected(error);

  const pathname = useLocation().pathname;
  const qafysLogo =
    "https://web-assets.deenschool.io/assets/logos/deen-school-logos-2x/deenschool-islamic-school-management-logo-cl.png";
  const dsLogoUrl = "https://portal.deenschool.io/";
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("home");
  const [schoolInfo, setSchoolInfo] = useState({});
  const [schoolLogoIcon, setSchoolLogoIcon] = useState("");
  const [schoolLogoIconName, setSchoolLogoIconName] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");
  const [schoolLogoName, setSchoolLogoName] = useState("");

  // useEffect(() => {
  //   setSchoolInfo(schoolInformation);
  // }, [schoolInformation])

  useEffect(() => {
    if (!isLoading && data && !error) {
      setSchoolInfo(data?.body?.data);
    }
  }, [data, error, isLoading]);

  useEffect(() => {
    setSchoolLogoIcon(schoolInfo?.schoolIcon || "");
    setSchoolLogoIconName(schoolInfo?.schoolIconName || "");
    setSchoolLogo(schoolInfo?.schoolLogo || "");
    setSchoolLogoName(schoolInfo?.schoolLogoName || "");
  }, [schoolInfo]);

  // const isTheSectionPermitted = (sectionName) => {
  //   let found = false
  //   rolesWithPermissions?.map(roles => {
  //     roles?.permissions?.map(singlePermission => {
  //       if (singlePermission?.name == sectionName) {
  //         found = true
  //       }
  //     })
  //   })
  //   return found
  // }

  return (
    <div className="SideBarContainer">
      <div className="SideBarInnerContainer">
        <div className="BrandMainLogo">
          {dsLogoUrl?.length > 0 && (
            <a
              className="Branding"
              href={dsLogoUrl?.length > 0 ? dsLogoUrl : "#"}
            >
              {/* <img
                src={schoolLogo != "" ? schoolLogo : qafysLogo}
                style={{ height: "40px", objectFit: "contain" }}
              /> */}
              <img
                src={websiteLogo}
                style={{ height: "70px", objectFit: "contain" }}
              />
            </a>
          )}
        </div>

        <div className="SideBarMenu">
          <Sidebar collapsed={props.collapsed}>
            <PortalSwitcher />

            <Menu>
              <CustomMenuItem
                className={`${pathname === "/" ? "LinkActive" : "link"}`}
                href="/"
              >
                <RiHome4Fill />
                <span className="MenuPer">QR Codes</span>{" "}
                <div className="MenuTooltip">
                  <a className="MenuTooltipLink" href="/settings">
                    QR Codes
                  </a>
                </div>
              </CustomMenuItem>
            </Menu>

            {/* ---------------------- School portal ------------------------ */}
            {props.SchoolRoute && (
              <Menu>
                {/* <CustomMenuItem className={`${pathname === '/listing-example' ? 'LinkActive' : 'link'}`} href="/listing-example"> <MenuBookRoundedIcon />  <span className="MenuPer">Table Example</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/listing-example">Table Example</a></div></CustomMenuItem> */}

                {/* <CustomMenuItem
                  className={`${pathname === '/' ? 'LinkActive' : 'link'}`}
                  href="/"
                >
                  <RxHome /> <span className="MenuPer">HOME</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/id">
                      HOME
                    </a>
                  </div>
                </CustomMenuItem> */}
                {/* {isTheSectionPermitted("attendance") && (
                  <SubMenu
                    defaultOpen={
                      pathname.includes("/") ||
                      pathname.includes("/attendance-history") ||
                      pathname.includes("/attendance-code") ||
                      pathname.includes("/attendance-settings")
                    }
                    className="MenuButtonPr"
                    label={
                      <div className="Submenue,">
                        <CalendarTodayOutlinedIcon />{" "}
                        <span className="MenuPer">Attendance</span>
                      </div>
                    }
                  >
                    <div className="submenutitle">Attendance</div>

                    <CustomMenuItem
                      className={`${pathname === "/" ? "LinkActive" : "link"}`}
                      href="/"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" /> Main
                      Attendance
                    </CustomMenuItem>

                    <CustomMenuItem
                      className={`${
                        pathname === "/attendance-history"
                          ? "LinkActive"
                          : "link"
                      }`}
                      href="/attendance-history"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                      Attendance History
                    </CustomMenuItem>

                    <CustomMenuItem
                      className={`${
                        pathname === "/attendance-code" ? "LinkActive" : "link"
                      }`}
                      href="/attendance-code"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                      Attendance Code{" "}
                    </CustomMenuItem>

                    <CustomMenuItem
                      className={`${
                        pathname === "/attendance-settings"
                          ? "LinkActive"
                          : "link"
                      }`}
                      href="/attendance-settings"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                      Attendance Settings{" "}
                    </CustomMenuItem>
                  </SubMenu>
                )} */}

                {/* {isTheSectionPermitted("messages") && (
                  <SubMenu
                    className="MenuButtonPr"
                    label={
                      <div className="Submenue">
                        <TextsmsOutlinedIcon />{" "}
                        <span className="MenuPer">Message</span>
                      </div>
                    }
                  >
                    <div className="submenutitle">MESSAGES</div>
                    <CustomMenuItem
                      className={`${
                        pathname === "/messages" ? "LinkActive" : "link"
                      }`}
                      href="/messages"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" />
                      Sent
                    </CustomMenuItem>

                    <CustomMenuItem
                      className={`${
                        pathname === "/templates" ? "LinkActive" : "link"
                      }`}
                      href="/templates"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" />
                      Templates
                    </CustomMenuItem>

                    <CustomMenuItem
                      className={`${
                        pathname === "/categories" ? "LinkActive" : "link"
                      }`}
                      href="/categories"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" />
                      Categories
                    </CustomMenuItem>
                  </SubMenu>
                )} */}

                {/* users */}
                {/* {isTheSectionPermitted("users") && (
                  <SubMenu
                    defaultOpen={
                      pathname.includes("/users") ||
                      pathname.includes("/groups") ||
                      pathname.includes("/invite") ||
                      pathname.includes("/group-details")
                    }
                    className="MenuButtonPr"
                    label={
                      <div className="Submenue">
                        <RxPerson /> <span className="MenuPer">Users</span>
                      </div>
                    }
                  >
                    <div className="submenutitle">Users</div>
                    {isTheActionPermitted("manageUsers") && (
                      <CustomMenuItem
                        className={`${
                          pathname.includes("/users") ? "LinkActive" : "link"
                        }`}
                        href="/users"
                      >
                        <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                        User List
                      </CustomMenuItem>
                    )}
                    {isTheActionPermitted("manageUserGroups") && (
                      <CustomMenuItem
                        className={`${
                          pathname.includes("/group-details") ||
                          pathname.includes("/groups")
                            ? "LinkActive"
                            : "link"
                        }`}
                        href="/groups"
                      >
                        <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                        User Groups
                      </CustomMenuItem>
                    )}

                    {isTheActionPermitted("inviteUsers") ? (
                      <CustomMenuItem
                        className={`${
                          pathname.includes("/invite") ? "LinkActive" : "link"
                        }`}
                        href="/invite-users"
                      >
                        <FiberManualRecordRoundedIcon className="DotIcon" />
                        Invite Users
                      </CustomMenuItem>
                    ) : (
                      ""
                    )}

                    {isTheActionPermitted("manageRolesPermissions") ? (
                      <CustomMenuItem
                        className={`${
                          pathname.includes("/roles-permissions")
                            ? "LinkActive"
                            : "link"
                        }`}
                        href="/roles-permissions"
                      >
                        <FiberManualRecordRoundedIcon className="DotIcon" />
                        Roles & Permissions
                      </CustomMenuItem>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                )} */}

                {/* <CustomMenuItem
                  className={`${pathname === '/id' ? 'LinkActive' : 'link'}`}
                  href="/id"
                >
                  <BadgeOutlinedIcon /> <span className="MenuPer">ID</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/id">
                      ID
                    </a>
                  </div>
                </CustomMenuItem> */}

                {/* {isTheSectionPermitted("academics") && (
                  <SubMenu
                    defaultOpen={
                      pathname.includes("/courses") ||
                      pathname.includes("/academic-years") ||
                      pathname.includes("/classes-level") ||
                      pathname.includes("/subject-area")
                    }
                    className="MenuButtonPr"
                    label={
                      <div className="Submenue">
                        <AutoStoriesOutlinedIcon />{" "}
                        <span className="MenuPer">Academics</span>
                      </div>
                    }
                  >
                    <div className="submenutitle">Academics</div>

                    {isTheActionPermitted("manageCourses") ? (
                      <CustomMenuItem
                        className={`${
                          pathname === "/courses" ? "LinkActive" : "link"
                        }`}
                        href="/courses"
                      >
                        <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                        Courses
                      </CustomMenuItem>
                    ) : (
                      ""
                    )}

                    {isTheActionPermitted("manageAcademicYears") && (
                      <CustomMenuItem
                        className={`${
                          pathname === "/academic-years" ? "LinkActive" : "link"
                        }`}
                        href="/academic-years"
                      >
                        <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                        Academic Years
                      </CustomMenuItem>
                    )}

                    {isTheActionPermitted("manageClassLevel") && (
                      <CustomMenuItem
                        className={`${
                          pathname === "/classes-level" ||
                          pathname.startsWith("/classes-level-details")
                            ? "LinkActive"
                            : "link"
                        }`}
                        href="/classes-level"
                      >
                        <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                        Class/Level{" "}
                      </CustomMenuItem>
                    )}

                    {isTheActionPermitted("manageSubjectArea") && (
                      <CustomMenuItem
                        className={`${
                          pathname === "/subject-area" ? "LinkActive" : "link"
                        }`}
                        href="/subject-area"
                      >
                        <FiberManualRecordRoundedIcon className="DotIcon" />{" "}
                        Subject Area{" "}
                      </CustomMenuItem>
                    )}
                  </SubMenu>
                )} */}

                {/* <CustomMenuItem
                  className={`${pathname === '/order-id' ? 'LinkActive' : 'link'
                    }`}
                  href="/order-id"
                >
                  <AiFillCalendar /> <span className="MenuPer">Order ID</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/order-id">
                      Order ID
                    </a>
                  </div>
                </CustomMenuItem> */}
                {/* <CustomMenuItem
                  className={`${
                    pathname === "/media-library-table-view"
                      ? "LinkActive"
                      : "link"
                  }`}
                  href="/media-library-table-view"
                >
                  <PermMediaOutlinedIcon />{" "}
                  <span className="MenuPer">Media</span>{" "}
                  <div className="MenuTooltip">
                    <a
                      className="MenuTooltipLink"
                      href="/media-library-table-view"
                    >
                      Media
                    </a>
                  </div>
                </CustomMenuItem> */}

                {/* {isTheSectionPermitted("settings") && (
                  <CustomMenuItem
                    className={`${
                      pathname === "/settings" ? "LinkActive" : "link"
                    }`}
                    href="/settings"
                  >
                    <AiOutlineSetting />{" "}
                    <span className="MenuPer">Settings</span>{" "}
                    <div className="MenuTooltip">
                      <a className="MenuTooltipLink" href="/settings">
                        Settings
                      </a>
                    </div>
                  </CustomMenuItem>
                )} */}

                {/* <CustomMenuItem
                  className={`${pathname === "/" ? "LinkActive" : "link"}`}
                  href="/"
                >
                  <RiHome4Fill />
                  <span className="MenuPer">QR Codes</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/settings">
                      QR Codes
                    </a>
                  </div>
                </CustomMenuItem> */}

                {/* <CustomMenuItem
                  onClick={() => dispatch(logout())}
                >
                  <LogoutIcon />{' '}
                  <span className="MenuPer">Logout</span>{' '}
                  <div className="MenuTooltip">
                    <span className="MenuTooltipLink">
                      Log Out
                    </span>
                  </div>
                </CustomMenuItem> */}

                {/* <span className="divider"></span>
                            <CustomMenuItem className={`${pathname === '/coupons' ? 'LinkActive' : 'link'}`} href="/coupons"><InsightsRoundedIcon /> <span className="MenuPer">Coupons</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/coupons">Coupons</a></div></CustomMenuItem> */}
              </Menu>
            )}
          </Sidebar>
        </div>
        {/* <img src={schoolLogo != "" ? schoolLogo : qafysLogo} style={{ height: "40px", objectFit: 'contain' }} /> */}
        {/* <div className='poweredByContainer'>
          <p>Powered by</p>
          <img src="https://web-assets.deenschool.io/assets/logos/deen-school-logos-2x/deenschool-islamic-school-management-logo-cl.png" alt='logo' />
        </div> */}
      </div>
    </div>
  );
}

export default SideBarMenu;
