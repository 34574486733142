import React, { useEffect, useState } from "react";
import TextField from "../../components/Form/TextField/TextField";
import {
  useGetSingleQrCodeQuery,
  useUpdateQrCodeMutation,
} from "../../redux/features/qrManagementapi/qrManagementApi";

const UTM = (props) => {
  const {
    urlUtmName,
    setUrlUtmName,
    utmClientProjName,
    setUtmClientProjName,
    utmBaseUrl,
    setUtmBaseUrl,
    utmCampaign,
    setUtmCampaign,
    utmMedium,
    setUtmMedium,
    utmSource,
    setUtmSource,
    utmErrors,
    itemId,
  } = props;

  const [itemDetails, setItemDetails] = useState(null);
  const { data, isError, isLoading } = useGetSingleQrCodeQuery(itemId);
  const [updateQrCode, { error: updateError }] = useUpdateQrCodeMutation();
  useEffect(() => {
    if (!isLoading && !isError) {
      console.log(data.body.data, 2012);
      setItemDetails(data.body.data);
    }
  }, [data, isError, isLoading]);

  useEffect(() => {
    if (itemDetails && itemDetails.name) {
      setUrlUtmName(itemDetails.name);
    }
    if (itemDetails && itemDetails.project) {
      setUtmClientProjName(itemDetails.project);
    }

    if (itemDetails && itemDetails.url) {
      setUtmBaseUrl(itemDetails.url);
    }

    if (itemDetails && itemDetails.utm_campaign) {
      setUtmCampaign(itemDetails.utm_campaign);
    }
    if (itemDetails && itemDetails.utm_medium) {
      setUtmMedium(itemDetails.utm_medium);
    }

    if (itemDetails && itemDetails.utm_source) {
      setUtmSource(itemDetails.utm_source);
    }
  }, [
    itemDetails,
    setUrlUtmName,
    utmClientProjName,
    setUtmClientProjName,
    utmBaseUrl,
    setUtmBaseUrl,
    utmCampaign,
    setUtmCampaign,
    utmSource,
    setUtmSource,
    utmMedium,
    setUtmMedium,
  ]);

  const handleInputChange = (field, event) => {
    const newValue = event;
    switch (field) {
      case "utmQrName":
        setUrlUtmName(newValue);
        break;
      case "utmClientProjName":
        setUtmClientProjName(newValue);
        break;
      case "utmBaseUrl":
        setUtmBaseUrl(newValue);
        break;
      case "utmCampaign":
        setUtmCampaign(newValue);
        break;
      case "utmMedium":
        setUtmMedium(newValue);
        break;
      case "utmSource":
        setUtmSource(newValue);
        break;
      default:
        console.error("Invalid field name");
        break;
    }
    console.log(newValue);
  };

  return (
    <div>
      {/* ========== QR name ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">QR code name</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Enter QR code name"
            count={40}
            name={"utmQrName"}
            required={true}
            helperText={utmErrors?.urlUtmName}
            error={utmErrors?.urlUtmName !== ""}
            value={urlUtmName}
            handleChange={(e) => handleInputChange("utmQrName", e)}
          />
        </div>
      </div>
      {/* ========== Client Project ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">Client/Project</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Enter client/project name"
            count={50}
            name={"utmClientProjName"}
            required={false}
            helperText={utmErrors?.utmClientProjName}
            error={utmErrors?.utmClientProjName !== ""}
            value={utmClientProjName}
            handleChange={(e) => handleInputChange("utmClientProjName", e)}
          />
        </div>
      </div>
      {/* ========== URL ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">Base URL</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="http://"
            name={"utmBaseUrl"}
            required={false}
            helperText={utmErrors?.utmBaseUrl}
            error={utmErrors?.utmBaseUrl !== ""}
            value={utmBaseUrl}
            handleChange={(e) => handleInputChange("utmBaseUrl", e)}
          />
        </div>
      </div>
      {/* ========== utm_campaign ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">utm_campaign</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder=""
            name={"utmCampaign"}
            required={false}
            helperText={utmErrors?.utmCampaign}
            error={utmErrors?.utmCampaign !== ""}
            value={utmCampaign}
            handleChange={(e) => handleInputChange("utmCampaign", e)}
          />
        </div>
      </div>
      {/* ========== utm_medium ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">utm_medium</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder=""
            name={"utmMedium"}
            required={false}
            helperText={utmErrors?.utmMedium}
            error={utmErrors?.utmMedium !== ""}
            value={utmMedium}
            handleChange={(e) => handleInputChange("utmMedium", e)}
          />
        </div>
      </div>
      {/* ========== utm_source ========== */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">utm_source</div>
        <div className="FormFieldCol">
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder=""
            name={"utmSource"}
            required={false}
            helperText={utmErrors?.utmSource}
            error={utmErrors?.utmSource !== ""}
            value={utmSource}
            handleChange={(e) => handleInputChange("utmSource", e)}
          />
        </div>
      </div>
    </div>
  );
};

export default UTM;
